<template>
  <v-card
    :class="{
      'internet-plan-card': true,
    }"
    :data-plan-id="plan.id"
    outlined
  >
    <v-card-title class="plan-name">
      {{ plan.name }}
    </v-card-title>
    <v-card-text class="plan-details">
      <div class="description">
        {{ plan.description }}
      </div>
      <div class="price">
        @Ksh {{ plan.price }}
      </div>
      <div v-if="!plan.description" class="validity">
        {{ formatHotspotBillingInterval(plan.validity_unit, plan.validity) }}
      </div>
      <v-btn
        v-if="!defaultPlan"
        color="primary"
        class="choose-plan-button mb-4"
        outlined
        @click="confirmPayment"
      >
        Select and buy
      </v-btn>
    </v-card-text>
    <mpesa-payment-dialog
      :show-dialog="showMpesaPaymentDialog"
      :show-amount="false"
      :customer="customer"
      :selected-plan="plan"
      :description="description"
      :amount="plan.price"
      :contact-number="userInfo.phone"
      @close="showMpesaPaymentDialog = false"
    ></mpesa-payment-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import billingIntervalName from '@/mixins/billingIntervalName'
import speedUnit from '@/mixins/speedUnit'
import MpesaPaymentDialog from '@/components/dialogs/InitiateMpesaPaymentDialog.vue'

export default {
  components: {
    MpesaPaymentDialog,
  },
  mixins: [billingIntervalName, speedUnit],
  props: {
    plan: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    defaultPlan: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default: null,
    },
    customerPlanId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showPlanChangeConfirmation: false,
      showPaymentConfirmation: false,
      isPlanBeingChanged: false,
      showMpesaPaymentDialog: false,
      toBeSelectedPlan: null,
      pendingPlanChangeId: null,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    isSpeedInName() {
      const speedWithUnit = this.plan.download_speed + this.speedUnit

      return this.plan.name.includes(speedWithUnit)
    },
    description() {
      return `You are about to pay Ksh ${this.plan.price} to purchase ${this.plan.name} internet bundle, click on 'PAY' button to proceed with payment.`
    },
  },
  methods: {
    confirmPayment() {
      this.showPaymentConfirmation = false
      this.toBeSelectedPlan = null
      this.showMpesaPaymentDialog = true
    },
    cancelPlanSelectionDialog() {
      this.showPlanChangeConfirmation = false
    },
  },
}
</script>

<style scoped>
.internet-plan-card {
  transition: background-color 0.3s ease;
  border: 2px solid transparent;
  margin-bottom: 20px;
  padding: 15px 2px;
}

.internet-plan-card:hover {
  background-color: #ebebeb; /* Default color for light theme */

  /* Styles for dark theme */
  &.theme--dark:hover {
    background-color: #45425d;
  }
}

.plan-name {
  font-weight: bold;
  font-size: 20px;
  padding: 10px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-details {
  text-align: center;
  padding: 10px;
}

.price {
  font-weight: bold;
  margin-bottom: 5px;
}

.choose-plan-button {
  margin-top: 25px;
}
.speed {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}
</style>
